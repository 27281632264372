import { Component, NgZone } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private afAuth: AngularFireAuth,
    private deeplinks: Deeplinks,
    private zone: NgZone
  ) {
    console.log(environment.production); // Logs false for development environment
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is("android"))
        this.statusBar.styleLightContent()
      else
        this.statusBar.styleLightContent();

      this.splashScreen.hide();
      this.initDeeplinks();
      if (localStorage.getItem('isLoggedIn') == 'true') {
        this.afAuth.onAuthStateChanged(user => {
          /*
          if (user == null)
            this.router.navigateByUrl('/login', { replaceUrl: true, skipLocationChange: true })
          else
            this.router.navigateByUrl('/tabs/home', { skipLocationChange: true, replaceUrl: true })
            */
        })
      }
      else {
        //this.router.navigateByUrl('/tabs/home');
      }
    
    });
  }

  initDeeplinks() {
    this.deeplinks.route({ '/:slug': 'blogs' }).subscribe(
    match => {
    const path = `/${match.$route}/${match.$args['slug']}`;
    // Run the navigation in the Angular zone
    this.zone.run(() => {
    this.router.navigateByUrl(path);
    });
    },
    nomatch => {
    console.log("Deeplink that didn't match", nomatch);
    });
    }
}
