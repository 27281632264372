import { MbscModule } from '@mobiscroll/angular';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { GooglePlus } from '@ionic-native/google-plus/ngx';
//import { Facebook } from '@ionic-native/facebook/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';

import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { File } from '@ionic-native/file/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';

import { SharedModule } from './services/share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ImagemodalPageModule } from './imagemodal/imagemodal.module'

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { environment } from '../environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ComponentsModule } from './components/components.module';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { mediaStateReducer } from './services/store';
import { StoreModule } from '@ngrx/store';
import { AudioProvider } from './services/audio.service';
import { CloudProvider } from './services/cloud.service';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { Crop } from '@ionic-native/crop/ngx';
@NgModule({
    declarations: [AppComponent],
    imports: [  
    MbscModule, 
        BrowserModule,
        IonicModule.forRoot({ mode: 'ios' }),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        AngularFireAuthModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        ImagemodalPageModule,
        BrowserAnimationsModule,
        ComponentsModule,
        GraphQLModule,
        HttpClientModule,
        StoreModule.forRoot({ appState: mediaStateReducer }),
        
    ],
    providers: [
        StatusBar,
        SplashScreen,
        GooglePlus,
        Crop,
        //Facebook,
        Camera,
        MediaCapture,
        File,
        FirebaseX,
        SignInWithApple,
        Keyboard,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Deeplinks ,
        AudioProvider,
        CloudProvider,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
