import {Injectable} from '@angular/core';
import { of } from 'rxjs';

@Injectable()
export class CloudProvider {
  private readonly newProperty = 'http://www.unclewash.net/assets/';

  files:any = [
    { 
      url: this.newProperty + '7.mp3',
      title  : 'drum',
      name: 'alan walker'
    },
    { 
      url: this.newProperty + '1.mp3',
      title  : 'Perfect',
      name: 'Ed Sheeran'
    },
    {
      url: this.newProperty + '2.mp3',
      title  : 'Man Atkeya Beparwah',
      name: 'Nusrat Fateh Ali Khan'
    },
    { 
      url: this.newProperty + '3.mp3',
      title  : 'Penny Lane',
      name: 'The Beatles'
    },
    { 
      url: this.newProperty + '4.mp3',
      title  : 'lifelike',
      name: 'by alexaction'
    },
    { 
      url: this.newProperty + '5.mp3',
      title  : 'classic guitar',
      name: 'king'
    },
    { 
      url: this.newProperty + '6.mp3',
      title  : 'abstract',
      name: 'comastudio'
    }
    

  ];
  getFiles() {
    return of(this.files);
  }
}