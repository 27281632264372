import { Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { EventsService } from 'src/app/services/event.serivce';
@Component({
  selector: 'app-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss']
})
export class TeamCardComponent implements OnInit {
  @Input() teamList : any;
  @Output() researchEvent  = new EventEmitter<{}>();
  userSeq: any;

  constructor(
    private apollo: Apollo,
    private events :EventsService,
  ) {

  }

  ngOnInit() {
    this.userSeq = localStorage.getItem('userseq');
    console.log('userseq',this.userSeq);
  }

  bookmark(item, i){
    this.updateBookMark(item.bookmarkSeq, item.seq, i);
  }

  updateBookMark(bookmarkSeq, itemSeq, i){
    console.log(bookmarkSeq, this.userSeq, itemSeq);
    
    this.apollo.mutate({
      mutation: gql`
      mutation{
        updateTeasmBookmark (model: {seq:${bookmarkSeq ?? 0}, userSeq:${this.userSeq}, refseq:${itemSeq}})
        {
          result
          data{
            seq
            userSeq
            refseq
            delyn
          }
        }
      }
      `,
      fetchPolicy: 'network-only'
      }).subscribe((res: any) => {
        console.log(res);
        var result:string = res.data?.updateTeasmBookmark.result;

        if(result == "Success") {
          var tmpteamList = this.teamList.map((team) => {
            return {...team};
          });

          if(bookmarkSeq == null || bookmarkSeq == 0){
            tmpteamList[i].isBookmarked = true;
            tmpteamList[i].bookmarkSeq = res.data.updateTeasmBookmark.data.seq;
          }
          else{
            tmpteamList[i].isBookmarked = false;
            tmpteamList[i].bookmarkSeq = null;
          }
          this.teamList = tmpteamList;
        }
    });
  }

}
