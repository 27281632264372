import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'blockedlist',
    loadChildren: () => import('./blockedlist/blockedlist.module').then(m => m.BlockedlistPageModule)
  },
  {
    path: 'friends',
    loadChildren: () => import('./friends/friends.module').then(m => m.FriendsPageModule)
  },
  {
    path: 'group/:id',
    loadChildren: () => import('./group/group.module').then(m => m.GroupPageModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('./groups/groups.module').then(m => m.GroupsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'message/:id',
    loadChildren: () => import('./message/message.module').then(m => m.MessagePageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then(m => m.MessagesPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'addmembers/:id',
    loadChildren: () => import('./addmembers/addmembers.module').then(m => m.AddmembersPageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./forgot/forgot.module').then(m => m.ForgotPageModule)
  },
  {
    path: 'groupinfo/:id',
    loadChildren: () => import('./groupinfo/groupinfo.module').then(m => m.GroupinfoPageModule)
  },
  {
    path: 'imagemodal',
    loadChildren: () => import('./imagemodal/imagemodal.module').then(m => m.ImagemodalPageModule)
  },
  {
    path: 'newgroup',
    loadChildren: () => import('./newgroup/newgroup.module').then(m => m.NewgroupPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'userinfo/:id',
    loadChildren: () => import('./userinfo/userinfo.module').then(m => m.UserinfoPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'teambuilding',
    loadChildren: () => import('./home/teambuilding/teambuilding.module').then( m => m.TeambuildingPageModule)
  },
  {
    path: 'feed',
    loadChildren: () => import('./home/feed/feed.module').then( m => m.FeedPageModule)
  },
  {
    path: 'comm',
    loadChildren: () => import('./home/comm/comm.module').then( m => m.CommPageModule)
  },
  {
    path: 'my',
    loadChildren: () => import('./home/my/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'my/:seq',
    loadChildren: () => import('./home/my/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'newbuilder',
    loadChildren: () => import('./home/teambuilding/newbuilder/newbuilder.module').then( m => m.NewbuilderPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./home/my/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'edit-name',
    loadChildren: () => import('./home/my/edit-name/edit-name.module').then( m => m.EditNamePageModule)
  },
  {
    path: 'edit-username',
    loadChildren: () => import('./home/my/edit-username/edit-username.module').then( m => m.EditUsernamePageModule)
  },
  {
    path: 'edit-intro',
    loadChildren: () => import('./home/my/edit-intro/edit-intro.module').then( m => m.EditIntroPageModule)
  },
  {
    path: 'edit-link',
    loadChildren: () => import('./home/my/edit-link/edit-link.module').then( m => m.EditLinkPageModule)
  },
  {
    path: 'add-link',
    loadChildren: () => import('./home/my/add-link/add-link.module').then( m => m.AddLinkPageModule)
  }, 
  {
    path: 'follow',
    loadChildren: () => import('./home/my/follow/follow.module').then( m => m.FollowPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./home/comm/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'search-result/:keyword',
    loadChildren: () => import('./home/comm/search-result/search-result.module').then( m => m.SearchResultPageModule)
  },
  {
    path: 'my-teambuilding',
    loadChildren: () => import('./home/setting/my-teambuilding/my-teambuilding.module').then( m => m.MyTeambuildingPageModule)
  },
  {
    path: 'my-request',
    loadChildren: () => import('./home/setting/my-request/my-request.module').then( m => m.MyRequestPageModule)
  },
  {
    path: 'my-comm',
    loadChildren: () => import('./home/setting/my-comm/my-comm.module').then( m => m.MyCommPageModule)
  },
  {
    path: 'my-music',
    loadChildren: () => import('./home/setting/my-music/my-music.module').then( m => m.MyMusicPageModule)
  },
  {
    path: 'bm',
    loadChildren: () => import('./messages/messages.module').then(m => m.MessagesPageModule)
  },
  {
    path: 'bookmark',
    loadChildren: () => import('./home/setting/bookmark/bookmark.module').then( m => m.BookmarkPageModule)
  },
  // #comm
  {
    path: 'board-main',
    loadChildren: () => import('./home/comm/board-main/board-main.module').then( m => m.BoardMainPageModule)
  },
  {
    path: 'board-main/:seq',
    loadChildren: () => import('./home/comm/board-main/board-main.module').then( m => m.BoardMainPageModule)
  },
  {
    path: 'lounge-main',
    loadChildren: () => import('./home/comm/lounge-main/lounge-main.module').then( m => m.LoungeMainPageModule)
  },
  {
    path: 'lounge-main/:seq',
    loadChildren: () => import('./home/comm/lounge-main/lounge-main.module').then( m => m.LoungeMainPageModule)
  },
  {
    path: 'write',
    loadChildren: () => import('./home/comm/write/write.module').then( m => m.WritePageModule)
  }, 
  {
    path: 'write/:type',
    loadChildren: () => import('./home/comm/write/write.module').then( m => m.WritePageModule)
  }, 
  {
    path: 'lounge-list',
    loadChildren: () => import('./home/comm/lounge-list/lounge-list.module').then( m => m.LoungeListPageModule)
  },
  {
    path: 'bookmark-list',
    loadChildren: () => import('./home/comm/bookmark-list/bookmark-list.module').then( m => m.BookmarkListPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./home/comm/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'comm-detail',
    loadChildren: () => import('./home/comm/comm-detail/comm-detail.module').then( m => m.CommDetailPageModule)
  }, 
  {
    path: 'comm-detail/:seq',
    loadChildren: () => import('./home/comm/comm-detail/comm-detail.module').then( m => m.CommDetailPageModule)
  }, 
  {
    path: 'banner-list',
    loadChildren: () => import('./home/comm/banner-list/banner-list.module').then( m => m.BannerListPageModule)
  },
  // #comm end
  {
    path: 'edit-feed',
    loadChildren: () => import('./home/my/edit-feed/edit-feed.module').then( m => m.EditFeedPageModule)
  },
  {
    path: 'edit-tag',
    loadChildren: () => import('./home/my/edit-tag/edit-tag.module').then( m => m.EditTagPageModule)
  },
  {
    path: 'more',
    loadChildren: () => import('./home/setting/more/more.module').then( m => m.MorePageModule)
  },
  {
    path: 'edit-account',
    loadChildren: () => import('./home/setting/edit-account/edit-account.module').then( m => m.EditAccountPageModule)
  },
  {
    path: 'edit-password',
    loadChildren: () => import('./home/setting/edit-password/edit-password.module').then( m => m.EditPasswordPageModule)
  },
  {
    path: 'edit-payment',
    loadChildren: () => import('./home/setting/edit-payment/edit-payment.module').then( m => m.EditPaymentPageModule)
  },
  {
    path: 'add-payment',
    loadChildren: () => import('./home/setting/add-payment/add-payment.module').then( m => m.AddPaymentPageModule)
  },
  {
    path: 'alram',
    loadChildren: () => import('./home/setting/alram/alram.module').then( m => m.AlramPageModule)
  },
  {
    path: 'alram-teambuilding',
    loadChildren: () => import('./home/setting/alram-teambuilding/alram-teambuilding.module').then( m => m.AlramTeambuildingPageModule)
  },
  {
    path: 'alram-request',
    loadChildren: () => import('./home/setting/alram-request/alram-request.module').then( m => m.AlramRequestPagePageModule)
  },
  {
    path: 'alram-music',
    loadChildren: () => import('./home/setting/alram-music/alram-music.module').then( m => m.AlramMusicPagePageModule)
  },
  {
    path: 'alram-board',
    loadChildren: () => import('./home/setting/alram-board/alram-board.module').then( m => m.AlramBoardPagePageModule)
  },
  {
    path: 'alram-follow',
    loadChildren: () => import('./home/setting/alram-follow/alram-follow.module').then( m => m.AlramFollowPagePageModule)
  },
  {
    path: 'alram-message',
    loadChildren: () => import('./home/setting/alram-message/alram-message.module').then( m => m.AlramMessagePagePageModule)
  },
  {
    path: 'alram-community',
    loadChildren: () => import('./home/setting/alram-community/alram-community.module').then( m => m.AlramCommunityPagePageModule)
  },
  {
    path: 'request-recommend',
    loadChildren: () => import('./home/teambuilding/component/request/request-recommend/request-recommend.module').then( m => m.RequestRecommendPageModule)
  },
  {
    path: 'request-deadline',
    loadChildren: () => import('./home/teambuilding/component/request/request-deadline/request-deadline.module').then( m => m.RequestDeadlinePageModule)
  },
  {
    path: 'edit-bank',
    loadChildren: () => import('./home/setting/edit-bank/edit-bank.module').then( m => m.EditBankPageModule)
  },
  {
    path: 'select-bank',
    loadChildren: () => import('./home/setting/select-bank/select-bank.module').then( m => m.SelectBankPageModule)
  },
  {
    path: 'request-detail/:seq',
    loadChildren: () => import('./home/teambuilding/component/request/request-detail/request-detail.module').then( m => m.RequestDetailPageModule)
  },
  {
    path: 'edit-card',
    loadChildren: () => import('./home/setting/edit-card/edit-card.module').then( m => m.EditCardPageModule)
  },
  {
    path: 'request-qa',
    loadChildren: () => import('./home/teambuilding/component/request/request-qa/request-qa.module').then( m => m.RequestQaPageModule)
  },
  {
    path: 'request-qa/:seq',
    loadChildren: () => import('./home/teambuilding/component/request/request-qa/request-qa.module').then( m => m.RequestQaPageModule)
  },
  {
    path: 'request-join',
    loadChildren: () => import('./home/teambuilding/component/request/request-join/request-join.module').then( m => m.RequestJoinPageModule)
  },
  {
    path: 'request-join/:seq',
    loadChildren: () => import('./home/teambuilding/component/request/request-join/request-join.module').then( m => m.RequestJoinPageModule)
  },
  {
    path: 'request-list/:seq',
    loadChildren: () => import('./home/teambuilding/component/request/request-list/request-list.module').then( m => m.RequestListPageModule)
  },
  {
    path: 'edit-request',
    loadChildren: () => import('./home/teambuilding/component/request/edit-request/edit-request.module').then( m => m.RequestJoinPageModule)
  },
  {
    path: 'inquiry',
    loadChildren: () => import('./home/setting/inquiry/inquiry.module').then( m => m.InquiryPageModule)
  },
  {
    path: 'request-opening',
    loadChildren: () => import('./home/teambuilding/component/request/request-opening/request-opening.module').then( m => m.RequestOpeningPageModule)
  },
  {
    path: 'recommend-recruit',
    loadChildren: () => import('./home/teambuilding/component/building/recommend-recruit/recommend-recruit.module').then( m => m.RecommendRecruitPageModule)
  },
  {
    path: 'new-team',
    loadChildren: () => import('./home/teambuilding/component/building/new-team/new-team.module').then( m => m.NewTeamPageModule)
  },
  {
    path: 'search-builder/:searchType',
    loadChildren: () => import('./home/teambuilding/component/building/search-builder/search-builder.module').then( m => m.SearchBuilderPageModule)
  },
  {
    path: 'select-builder/:seq/:type',
    loadChildren: () => import('./home/teambuilding/component/building/select-builder/select-builder.module').then( m => m.SelectBuilderPageModule)
  },
  {
    path: 'select-builder-result/:seq/:type/:keyword',
    loadChildren: () => import('./home/teambuilding/component/building/select-builder-result/select-builder-result.module').then( m => m.SelectBuilderResultPageModule)
  },
  {
    path: 'search-builder',
    loadChildren: () => import('./home/teambuilding/component/building/search-builder/search-builder.module').then( m => m.SearchBuilderPageModule)
  },
  {
    path: 'select-area/:seq',
    loadChildren: () => import('./home/teambuilding/component/building/select-area/select-area.module').then( m => m.SelectAreaPageModule)
  },
  {
    path: 'recruit',
    loadChildren: () => import('./home/teambuilding/component/building/recruit/recruit.module').then( m => m.RecruitPageModule)
  },
  {
    path: 'build-search',
    loadChildren: () => import('./home/teambuilding/component/building/build-search/build-search.module').then( m => m.BuildSearchPageModule)
  },
  {
    path: 'edit-recruit',
    loadChildren: () => import('./home/teambuilding/component/building/edit-recruit/edit-recruit.module').then( m => m.EditRecruitPageModule)
  },
  {
    path: 'modify-recruit/:seq',
    loadChildren: () => import('./home/teambuilding/component/building/modify-recruit/modify-recruit.module').then( m => m.ModifyRecruitPageModule)
  },
  {
    path: 'recruit-detail/:seq/:backlocation',
    loadChildren: () => import('./home/teambuilding/component/building/recruit-detail/recruit-detail.module').then( m => m.RecruitDetailPageModule)
  },
  {
    path: 'recruit-detail',
    loadChildren: () => import('./home/teambuilding/component/building/recruit-detail/recruit-detail.module').then( m => m.RecruitDetailPageModule)
  },
  {
    path: 'recruit-qa/:seq',
    loadChildren: () => import('./home/teambuilding/component/building/recruit-qa/recruit-qa.module').then( m => m.RecruitQaPageModule)
  },
  {
    path: 'recruit-qa',
    loadChildren: () => import('./home/teambuilding/component/building/recruit-qa/recruit-qa.module').then( m => m.RecruitQaPageModule)
  },
  {
    path: 'edit-team',
    loadChildren: () => import('./home/teambuilding/component/building/edit-team/edit-team.module').then( m => m.EditTeamPageModule)
  },
  {
    path: 'edit-team/:seq',
    loadChildren: () => import('./home/teambuilding/component/building/edit-team/edit-team.module').then( m => m.EditTeamPageModule)
  },
  {
    path: 'add-team',
    loadChildren: () => import('./home/teambuilding/component/building/add-team/add-team.module').then( m => m.AddTeamPageModule)
  },
  {
    path: 'create-chat',
    loadChildren: () => import('./create-chat/create-chat.module').then( m => m.CreateChatPageModule)
  },
  {
    path: 'edit-chat',
    loadChildren: () => import('./edit-chat/edit-chat.module').then( m => m.EditChatPageModule)
  },
  {
    path: 'chat-setting',
    loadChildren: () => import('./chat-setting/chat-setting.module').then( m => m.ChatSettingPageModule)
  },
  {
    path: 'payment-reward',
    loadChildren: () => import('./home/teambuilding/component/request/payment-reward/payment-reward.module').then( m => m.PaymentRewardPageModule)
  },
  {
    path: 'feed-search',
    loadChildren: () => import('./home/feed/feed-search/feed-search.module').then( m => m.FeedSearchPageModule)
  },
  {
    path: 'feed-detail',
    loadChildren: () => import('./home/feed/feed-detail/feed-detail.module').then( m => m.FeedDetailPageModule)
  },
  {
    path: 'feed-comment',
    loadChildren: () => import('./home/feed/feed-comment/feed-comment.module').then( m => m.FeedCommentPageModule)
  },
  {
    path: 'team-card/:seq',
    loadChildren: () => import('./home/teambuilding/component/building/team-card/team-card.module').then( m => m.TeamCardPageModule)
  },
  {
    path: 'edit-soundtrack',
    loadChildren: () => import('./home/teambuilding/component/music/edit-soundtrack/edit-soundtrack.module').then( m => m.EditSoundtrackPageModule)
  },
  {
    path: 'music-search',
    loadChildren: () => import('./home/teambuilding/component/music/music-search/music-search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'music-search-result/:keyword',
    loadChildren: () => import('./home/teambuilding/component/music/music-search-result/music-search-result.module').then( m => m.MusicSearchResultPageModule)
  },
  {
    path: 'alram-list',
    loadChildren: () => import('./home/my/alram-list/alram-list.module').then( m => m.AlramListPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
