import { Injectable } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  area: string[] = ['서울특별시', '부산광역시', '인천광역시', '대구광역시', '광주광역시', '대전광역시', '울산광역시',
  '세종시', '경기도', '충청북도', '충청남도', '경상북도', '경상남도', '전라북도', '전라남도', '제주도', '강원도']
  
  position: string[] = ['보컬리스트', '작곡가', '작사가', '편곡가', '프로듀서', '연주자', '코러스', '마스터 엔지니어',
  '믹스 엔지니어', '댄서', 'DJ', '래퍼']

  role: string[] = ['보컬', '프로듀싱', '믹싱', '작곡', '연주', '마스터링', '작사', '코러스', 'DJ', '편곡', '안무', '랩'];

  goal: string[] = ['음원작업', '사이드 프로젝트', '포트폴리오' ,'친목', '리퀘스트 참가', '공연', '대회']
  
  genre: string[] = ['발라드', '댄스', '랩/힙합', '아이돌', 'R&B/Soul', '인디', '록/메탈', '트로트', '프코/블루스',
  '일렉트로니카', '국악', '뮤지컬', '재즈', '뉴에이지', '캐롤', 'OST', '키즈', '클래식', '오페라']

  reward: string[] = ['10만원 이하', '20만원 이하', '30만원 이하', '50만원 이하', '100만원 이하', '200만원 이하', '300만원 이하', '500만원 이하', '500만원 이상'];

  category: string[] = ['광고', '유튜브', '애니메이션', 'CCM', '게임', '드라마OST', '영화OST', '예능'];
  
  constructor(
    public loadingProvider: LoadingService,
  ) { }

}
