import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
@Injectable()
export class SearchFilterPipe implements PipeTransform {
  // SearchPipe
  // Filter user search results for name or username excluding the excludedIds.
  transform(searchList: any, data: any): any {
    
    if(data[0].area[0] && data[0].area.length != 0)
      searchList = searchList.filter((element) => {
        for(var key in data[0].area){
          if(element.mainarea == data[0].area[key])
            return true;
        }
      }
    );

    if(data[0].position[0] && data[0].position[0].length != 0)
      searchList = searchList.filter(element => {
        for(var key in data[0].position){
          if(element.recruitposition == data[0].position[key])
            return true; 
        }
      }
    );

    if(data[0].genre[0] && data[0].genre[0].length != 0)
      searchList = searchList.filter(element => {
        for(var key in data[0].genre){
          if(element.genre == data[0].genre[key])
            return true;
        }
      }
    );
    
    return searchList
  }
}
