import { Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnimationLifecycle } from '@ionic/angular';
import { Apollo, gql } from 'apollo-angular';
import { EventsService } from 'src/app/services/event.serivce';
@Component({
  selector: 'app-recruit-card',
  templateUrl: './recruit-card.component.html',
  styleUrls: ['./recruit-card.component.scss']
})
export class RecruitCardComponent implements OnInit {
  @Input() setData: any;
  @Output() researchEvent  = new EventEmitter<{}>();

  userSeq : any;
  recruitList : any;
  listType : any;
  backlocation : any;

  constructor(
    private apollo: Apollo,
    private events :EventsService,
  ) {

  }

  ngOnInit() {
    this.userSeq = localStorage.getItem('userseq');
    console.log('userseq',this.userSeq);

    this.recruitList = this.setData[0];
    this.listType = this.setData[1];
    this.backlocation = this.setData[2];

    this.events.getObservable().subscribe(async (data) => {
      if(data.msg.includes("RecruitBookmark")){
        console.log(data);
        if(data.msg == 'RecruitBookmarkOn'){
          var tmplist = this.recruitList.map((recruit) => {
            if (recruit.seq == data.bookmarkdata.refseq) {
              return {
                ...recruit,
                bookmarkRecruitteams: [{ ...data.bookmarkdata }]
              };
            }
            return recruit;
          });
          
          this.recruitList = tmplist;
        }
        else if(data.msg == 'RecruitBookmarkOff'){
          var tmplist = this.recruitList.map((recruit) => {
            if (recruit.seq == data.bookmarkdata.refseq) {
              return {
                ...recruit,
                bookmarkRecruitteams: []
              };
            }
            return recruit;
          });
          
          this.recruitList = tmplist;
        }
        console.log(this.recruitList);
      }
    });
  }

  bookmark(item, bool){
    if(bool)
      this.updateBookMark(item.bookmarkRecruitteams[0].seq, item.seq);
    else
      this.updateBookMark(0, item.seq);
  }

  updateBookMark(bookmarkSeq, itemSeq){
    console.log(bookmarkSeq, this.userSeq, itemSeq);

    this.apollo.mutate({
      mutation: gql`
      mutation{
        updateRecteamBookmark (model: {seq:${bookmarkSeq}, userSeq:${this.userSeq}, refseq:${itemSeq}})
        {
          result
          data{
            seq
            userSeq
            refseq
            delyn
          }
        }
      }
      `,
      fetchPolicy: 'network-only'
      }).subscribe((res: any) => {
        var result = res.data?.updateRecteamBookmark.result;

        if (res.data && result == 'Success') {
          if(bookmarkSeq == 0){
            this.events.publish({msg: 'RecruitBookmarkOn', bookmarkdata: res.data?.updateRecteamBookmark.data});
          }
          else{
            this.events.publish({msg: 'RecruitBookmarkOff', bookmarkdata: res.data?.updateRecteamBookmark.data});
          }
        }
    });
  }
}
