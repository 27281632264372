import { Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnimationLifecycle } from '@ionic/angular';
import { Apollo, gql } from 'apollo-angular';
import { EventsService } from 'src/app/services/event.serivce';
@Component({
  selector: 'app-qa-card',
  templateUrl: './qa-card.component.html',
  styleUrls: ['./qa-card.component.scss']
})
export class QACardComponent implements OnInit {
  @Input() setData: any;
  @Output() researchEvent  = new EventEmitter<{}>();

  userSeq : any;
  recSeq : any;
  ansuseerSeq : any;
  qaType : any;

  qaList : any;

  openIndex: any;
  answer: any;
  question: any;

  issecret: boolean = false;
  isQAPage: boolean = true;

  constructor(
    private apollo: Apollo,
    private events :EventsService,
  ) {

  }

  ngOnInit() {
    this.userSeq = localStorage.getItem('userseq');
    console.log('userseq',this.userSeq);

    this.recSeq = this.setData[0];
    this.qaList = this.setData[1];
    this.qaType = this.setData[2];
    this.isQAPage = this.setData[3];

    if(this.qaType == "request"){
      this.queryRequestList().valueChanges.subscribe((res : any) => {
        console.log(res);
        this.ansuseerSeq = res.data?.reqPostList.items[0].userSeq;
        console.log(this.ansuseerSeq);
      });
    }
    else if(this.qaType == "recruit"){
      this.queryRecruitList().valueChanges.subscribe((res : any) => {
        console.log(res);
        this.ansuseerSeq = res.data?.recruitList.items[0].userseq;
        console.log(this.ansuseerSeq);
      });
    }
  }

  queryRequestList(){
    return this.apollo
    .watchQuery({
      query: gql`
      {
        reqPostList(where: {seq: {eq: ${this.recSeq}}}) {
          items {
            userSeq
          }
        }
      }`
    });
  }

  queryRecruitList(){
    return this.apollo
    .watchQuery({
      query: gql`
      {
        recruitList(where: {seq: {eq: ${this.recSeq}}}) {
          items {
            userseq
          }
        }
      }`
    });
  }

  openAnswerInput(item){
    if(this.openIndex == item)
      this.openIndex = 0;
    else
      this.openIndex = item;

    this.answer = null;
    console.log(this.openIndex);
  }

  addQuestion(ansuseerSeq){
    var secretYN = ""

    if(this.issecret)
      secretYN = "Y"
    else
      secretYN = "N"

    if(this.qaType == "request"){
      this.apollo.mutate({
        mutation: gql`
        mutation{
          updateRequestQA(model: {seq: 0, userseq: ${this.userSeq}, ansuseerseq: ${ansuseerSeq}, requestSeq: ${this.recSeq}, question: """${this.question}""", issecret: "${secretYN}"})
          {
            result
          }
        }
        `,
        fetchPolicy: 'network-only'
        }).subscribe((res: any) => {
          console.log(res);
          var result:string = res.data?.updateRequestQA.result;

          if(result == "Success") {
            this.question = null;
            this.issecret = false;
            this.researchEvent.emit();
          }
      });
    }
    else if(this.qaType == "recruit"){
      this.apollo.mutate({
        mutation: gql`
        mutation{
          updateRecteamQA (model: {seq: 0, userseq: ${this.userSeq}, ansuseerseq: ${ansuseerSeq}, recSeq: ${this.recSeq}, question: """${this.question}""", issecret: "${secretYN}"})
          {
            result
          }
        }
        `,
        fetchPolicy: 'network-only'
        }).subscribe((res: any) => {
          console.log(res);
          var result:string = res.data?.updateRecteamQA.result;
  
          if(result == "Success") {
            this.question = null;
            this.issecret = false;
            this.researchEvent.emit();
          }
      });
    }
  }

  updateAnswer(item){
    if(this.qaType == "request"){
      this.apollo.mutate({
        mutation: gql`
        mutation{
          updateRequestQA(model: {seq:${item.seq}, answer:"""${this.answer}"""})
          {
            result
          }
        }
        `,
        fetchPolicy: 'network-only'
        }).subscribe((res: any) => {
          console.log(res);
          var result:string = res.data?.updateRequestQA.result;
  
          if(result == "Success") {
            this.researchEvent.emit();
          }
      });
    }
    else if(this.qaType == "recruit"){
      this.apollo.mutate({
        mutation: gql`
        mutation{
          updateRecteamQA (model: {seq:${item.seq}, answer:"""${this.answer}"""})
          {
            result
          }
        }
        `,
        fetchPolicy: 'network-only'
        }).subscribe((res: any) => {
          console.log(res);
          var result:string = res.data?.updateRecteamQA.result;
  
          if(result == "Success") {
            this.researchEvent.emit();
          }
      });
    }
  }
}
