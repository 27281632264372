import { createReducer, on } from '@ngrx/store';
import * as MediaActions from './media.actions';

export interface MediaState {
  canplay: boolean;
  loadedmetadata: boolean;
  duration: number;
  durationSec: number;
  mediaType: string;
  playing: boolean;
  time: string;
  timeSec: number;
  loadstart: boolean;
  playerstate:string;
  initplaylist:boolean;
}

export const initialState: MediaState = {
  canplay: false,
  loadedmetadata: false,
  duration: 0,
  durationSec: 0,
  mediaType: '',
  playing: false,
  time: '0',
  timeSec: 0,
  loadstart: false,
  playerstate:'inactive',
  initplaylist:false
};

export const mediaStateReducer = createReducer(
  initialState,
  on(MediaActions.canplay, (state, { value }) => ({
    ...state,
    canplay: value,
  })),
  on(MediaActions.loadedmetadata, (state, { value, data }) => ({
    ...state,
    loadedmetadata: value,
    duration: data.time,
    durationSec: data.timeSec,
    mediaType: data.mediaType,
  })),
  on(MediaActions.playing, (state, { value }) => ({
    ...state,
    playing: value,
  })),
  on(MediaActions.timeupdate, (state, { time, timeSec }) => ({
    ...state,
    time,
    timeSec,
  })),
  on(MediaActions.loadstart, (state, { value }) => ({
    ...state,
    loadstart: value,
  })),
  on(MediaActions.playerstate, (state, { value }) => ({
    ...state,
    playerstate: value,
  })),
  on(MediaActions.initplaylist, (state, { value, data }) => ({
    ...state,
    loadedmetadata: value,
    currentFile: data.currentFile,
    files: data.files,
   
  })),

  on(MediaActions.reset, () => initialState),
  
);
