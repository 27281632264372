import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_FLAGS, APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, InMemoryCache,ApolloLink} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { onError } from '@apollo/client/link/error';
import { LoadingService } from './services/loading.service';
import { environment } from 'src/environments/environment';
import { createUploadLink } from 'apollo-upload-client';



export function createApollo(svc:LoadingService): ApolloClientOptions<any> {
  
  const error = onError(({ graphQLErrors, networkError }) => { // need help on linking this with graphql module
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
  
    if (networkError){
      console.log(`[Network error]: ${networkError}`);
      svc.showToast('인터넷 연결 오류입니다.')
    } 
  });
  
   const httpLink = createUploadLink({ uri: environment.uri });

  return {
    link: error.concat( httpLink),
    cache: new InMemoryCache(),
    
    defaultOptions : {
      watchQuery  : {
        fetchPolicy : 'network-only'
      }
    }
   
  };
}


@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useInitialLoading: true, // query 사용시 loading 상태관리 enable it here
      },
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [LoadingService],
    },
  ],
})
export class GraphQLModule {
  constructor(private toast : LoadingService) {}


}


