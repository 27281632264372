import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators';
import { AudioProvider } from 'src/app/services/audio.service';
import { CloudProvider } from 'src/app/services/cloud.service';

import {canplay, loadedmetadata, playing, timeupdate, loadstart, reset, playerstate} from 'src/app/services/media.actions';
@Component({
  selector: 'app-audioplayer',
  templateUrl: './audioplayer.component.html',
  styleUrls: ['./audioplayer.component.scss'],
   animations: [
    
    trigger('showHide', [
      state(
        'active',
        style({
          opacity: 1,
          transform: 'translateX(0%)'
        })
      ),
      state(
        'inactive',
        style({
          opacity: 0,
          transform: 'translateX(100%)'
        })
      ),
      transition('inactive => active', animate('250ms ease-in')),
      transition('active => inactive', animate('250ms ease-out'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class AudioplayerComponent implements OnInit{
  @Input() playerEvent :string;
  @Output() dataEvent  = new EventEmitter<{files: any[], currentFile: any}>();

  files: any = [];
  state: any = {};
  currentFile: any = {};
  playsection : string;

  segmentValue: any;

  onSeekState: boolean;
 
  displayPlayer: string = "inactive";
  displayPanel :string = "active";
  loggedIn: Boolean;
  progress : any;

  constructor(
    public cloudProvider: CloudProvider,
    private store: Store<any>,
    public audioProvider: AudioProvider,
    ) { 
   //   this.getDocuments();
      
      
    }
    
  ngOnInit(): void {
    console.log('nginit')
    this.store.dispatch(reset());
    this.store.select('appState').subscribe(value => {
      
      this.state = value;
    
    });

    this.store
    .select('appState')
    .pipe(pluck('canplay'), filter(value => value === true))
    .subscribe(() => {
  //    console.log('resized')
  //    this.displayFooter = 'active';
  //      console.log(this.state)
    });

  

  // Updating the Seekbar based on currentTime
  this.store
    .select('appState')
    .pipe(
      pluck('timeSec'),
      filter(value => value !== undefined),
      map((value: any) => Number.parseInt(value)),
      distinctUntilChanged()
    )
    .subscribe((value: any) => {
      this.progress = value;
    });

    this.store
    .select('appState')
    .pipe(
      pluck('playerstate'),
    )
    .subscribe((value: any) => {
      this.displayPlayer = value;
    });

    this.store
    .select('appState')
    .pipe(
      pluck('currentFile'),
      filter(value => value !== undefined),
      map((value: any) => value),
      distinctUntilChanged()
    )
    .subscribe((value: any) => {
      console.log(value)
      this.currentFile = value;
    
        this.openFile(this.currentFile.file, 0);
    });

    this.store
    .select('appState')
    .pipe(
      pluck('files'),
      filter(value => value !== undefined),
      map((value: any) => value),
      distinctUntilChanged()
    )
    .subscribe((value: any) => {
      this.files = value;
      
    
    
   
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // 속성 변경을 감지하고 이벤트 처리
    console.log('Input property changed:', changes.playerEvent.currentValue);

    const myPropChange = changes['playerEvent'];
    
    if (myPropChange) {
      const currentValueAsString = myPropChange.currentValue != null ? myPropChange.currentValue.toString() : '';
  
      if (typeof currentValueAsString === 'string') {
        const action = currentValueAsString.split('_')[0];
        
        switch(action) {
          case 'previous': {
        
            this.previous();
            break;
          }
          case 'next': {
          
            this.next();
            break;
          }
        }
      }
    }
    
  }

  previous() {
    
    let index = this.currentFile.index - 1;
    let file = this.files[index];
    this.openFile(file, index);
  
  }

  next() {
   
    let index = this.currentFile.index + 1;
    let file = this.files[index];
   this.openFile(file, index);
  }


  openFile(file, index) {
   
    console.log('open file',file,this.currentFile,index)
  
   

      
    this.currentFile = { index, file };
    //this.dataEvent.emit(data);
    this.playStream(file.trackurl);
  
  }

  

  playStream(url) {
  //  this.resetState();
    this.audioProvider.playStream(url).subscribe((event:any) => {
      //console.log(event);
    

      const audioObj = event.target;
      //console.log(event.type);
      switch (event.type) {
       
        case 'canplay': 
          return this.store.dispatch(canplay({  value: true  }));
        
        case 'loadedmetadata':
          return this.store.dispatch(loadedmetadata({value: true,data: {
            time: this.audioProvider.formatTime(
              audioObj.duration * 1000,
              'HH:mm:ss'
            ),
            timeSec: audioObj.duration,
            mediaType: 'mp3'
          }}));

        case 'playing':
          return this.store.dispatch(playing({ value: true }));

        case 'pause':
          return this.store.dispatch(playing({ value: false }));

        case 'timeupdate':
         
          return this.store.dispatch(timeupdate({
              timeSec: audioObj.currentTime,
              time: this.audioProvider.formatTime(
                audioObj.currentTime * 1000,
                'HH:mm:ss'
              )
          }));

        case 'loadstart':
          return this.store.dispatch(loadstart( {value: true } ));

        
      }
    });
  }
  
  
  resetState() {
    this.audioProvider.stop();
    this.store.dispatch(reset());
  }
  pause() {
    console.log('pause')
    this.audioProvider.pause();
  }

  play() {
   
   

    this.audioProvider.play();
  }

  isRotated = false;
  toggle(event: MouseEvent){
   
    this.isRotated = !this.isRotated;
    this.rotateIcon();
    this.displayPanel = this.displayPanel === 'inactive' ? 'active' : 'inactive';
     
  //   this.displayPlayer = this.displayPlayer === 'inactive' ? 'active' : 'inactive';
  }

  rotateIcon() {
    const icon = document.querySelector('.rotate-icon') as HTMLElement;
    if (icon) {
      if (this.isRotated) {
        icon.classList.add('rotated');
      } else {
        icon.classList.remove('rotated');
      }
    }
  }

  isFirstPlaying() {
    if (this.files.length === undefined){
      return true;
      
    }
  
    return this.currentFile.index === 0;
  }

  isLastPlaying() {

   if (this.files.length === undefined){
    return true;
    
  }
  else{
    return this.currentFile.index === this.files.length - 1;
  }
  }

  close()
  {
    console.log('close')
  

    this.resetState();
  }
}