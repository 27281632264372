import { NgModule } from '@angular/core';


import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { AudioplayerComponent } from './audioplayer/audioplayer.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { TeamCardComponent } from './team-card/team-card.component';
import { RecruitCardComponent } from './recruit-card/recruit-card.component';
import { QACardComponent } from './qa-card/qa-card.component';
import { SharedModule } from '../services/share.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    ShowHidePasswordComponent,
    AudioplayerComponent,
    SearchFilterComponent,
    TeamCardComponent,
    RecruitCardComponent,
    QACardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    ShowHidePasswordComponent,
    AudioplayerComponent,
    SearchFilterComponent,
    TeamCardComponent,
    RecruitCardComponent,
    QACardComponent
  ]
})
export class ComponentsModule {}