import { Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonInput } from '@ionic/angular';
import fa from '@mobiscroll/angular/dist/js/i18n/fa';
import { FilterService } from 'src/app/services/filter.service';
@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {
  @Input() sortSearchFilter:[];
  @Output() dataEvent  = new EventEmitter<{filter: any}>();

  isModalOpen: boolean = false;
  segmentValue: string = 'area';

  selectAreas: string[] = [];
  selectPositions: string[] = [];
  selectGoal: string[] = [];
  selectGenres: string[] = [];
  selectReward: string[] = [];
  selectCategory: string[] = [];

  selectFilter: string[] = [];

  filter: any[] = [ {"area": [], "position": [], "goal": [], "genre": [], "reward": [], "category": []} ];

  constructor(
    private filterService: FilterService,
  ) {

  }

  selectItem(event, item, type){
    console.log(event.target.checked, item, type);
    
    if(!this.selectFilter.includes(item)){
        if(!event.target.checked)
            return;
        
        this.selectFilter.push(item);
    }
    else
        this.selectFilter = this.selectFilter.filter((element) => element !== item);

    console.log(this.selectFilter);

    if(type == 'area'){
      if(!this.selectAreas.includes(item)){
        if(!event.target.checked)
          return;
        
        this.selectAreas.push(item);
      }
      else
        this.selectAreas = this.selectAreas.filter((element) => element !== item);
    
      console.log(this.selectAreas);
    }
    else if(type == 'position'){
      if(!this.selectPositions.includes(item)){
        if(!event.target.checked)
          return;
        
        this.selectPositions.push(item);
      }
      else
        this.selectPositions = this.selectPositions.filter((element) => element !== item);
    
      console.log(this.selectPositions);
    }
    else if(type == 'goal'){
      if(!this.selectGoal.includes(item)){
        if(!event.target.checked)
          return;
        
        this.selectGoal.push(item);
      }
      else
        this.selectGoal = this.selectGoal.filter((element) => element !== item);
    }
    else if(type == 'genre'){
      if(!this.selectGenres.includes(item)){
        if(!event.target.checked)
          return;
        
        this.selectGenres.push(item);
      }
      else
        this.selectGenres = this.selectGenres.filter((element) => element !== item);
    
      console.log(this.selectGenres);
    }
    else if(type == 'reward'){
      if(!this.selectReward.includes(item)){
        if(!event.target.checked)
          return;
        
        this.selectReward.push(item);
      }
      else
        this.selectReward = this.selectReward.filter((element) => element !== item);
    
      console.log(this.selectReward);
    }
    else if(type == 'category'){
      if(!this.selectCategory.includes(item)){
        if(!event.target.checked)
          return;
        
        this.selectCategory.push(item);
      }
      else
        this.selectCategory = this.selectCategory.filter((element) => element !== item);
    
      console.log(this.selectCategory);
    }
  }

  arrClaer(){
      this.selectFilter = [];
      this.selectAreas = [];
      this.selectPositions = [];
      this.selectGoal = [];
      this.selectGenres = [];
      this.selectReward = [];
      this.selectCategory = [];
  }

  deselcetItem(item){
    this.selectFilter = this.selectFilter.filter((element) => element !== item);

    if(this.selectAreas.includes(item))
      this.selectAreas = this.selectAreas.filter((element) => element !== item);

    if(this.selectPositions.includes(item))
      this.selectPositions = this.selectPositions.filter((element) => element !== item);

    if(this.selectGoal.includes(item))
      this.selectGoal = this.selectGoal.filter((element) => element !== item);

    if(this.selectGenres.includes(item))
      this.selectGenres = this.selectGenres.filter((element) => element !== item);

    if(this.selectReward.includes(item))
    this.selectReward = this.selectReward.filter((element) => element !== item);

    if(this.selectCategory.includes(item))
    this.selectCategory = this.selectCategory.filter((element) => element !== item);
  }

  addFilter(){
    this.filter =  [ {"area": this.selectAreas, "position": this.selectPositions, "goal": this.selectGoal, "genre": this.selectGenres, "reward": this.selectReward, "category": this.selectCategory} ];
    
    this.dataEvent.emit(this.filter);
  }

  ngOnInit() {
    console.log(this.sortSearchFilter);
  }
  
  segmentChanged(e){
    console.log(e.detail.value);
    this.segmentValue = e.detail.value;
  }
}
