import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'musicFilter'
})
@Injectable()
export class MusicPipe implements PipeTransform {
  // FriendPipe
  // Filter friend by name or username.
  transform(musics: any[], search: string): any {
    if (!musics) {
      return;
    } else if (!search) {
      return musics;
    } else {
      console.log(musics)
      let term = search.toLowerCase();
      return musics.filter(music => music.strackseqNavigation.title.toLowerCase().indexOf(term) > -1); // || music.strackseqNavigation.name.toLowerCase().indexOf(term) > -1
    }
  }
}
