import { NgModule } from '@angular/core';
import { FriendPipe } from '../pipes/friend';
import { ConversationPipe } from '../pipes/conversation';
import { GroupPipe } from '../pipes/group';
import { SearchPipe } from '../pipes/search';
import { DateFormatPipe } from '../pipes/date';
import { MusicPipe } from '../pipes/music';
import { DDayFormat } from '../pipes/d-day';
import { SearchFilterPipe } from '../pipes/search-filter';
import { DatePipe } from '@angular/common';


@NgModule({
    imports: [
    ],
    declarations: [
        FriendPipe,
        ConversationPipe,
        GroupPipe,
        SearchPipe,
        DateFormatPipe,
        MusicPipe,
        DDayFormat,
        SearchFilterPipe,
    ],
    providers: [
        DatePipe,
    ],
    exports: [
        FriendPipe,
        ConversationPipe,
        GroupPipe,
        SearchPipe,
        DateFormatPipe,
        MusicPipe,
        DDayFormat,
        SearchFilterPipe,
    ]
})

export class SharedModule { }