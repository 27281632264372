import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'DateFormat'
})

@Injectable()
export class DateFormatPipe implements PipeTransform {
  transform(date: any, args?: any): any {
        //return moment(new Date(date)).fromNow(); // 오늘인경우 시간 기타는 날자로
    const momentDate = moment(new Date(date));
    const today = moment();

    if (momentDate.isSame(today, 'day')) {
      return momentDate.format('h:mm A');
    } else {
      return momentDate.format('YYYY M D,h:mm A');
    }
  }

}

