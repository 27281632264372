import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'DDayFormat'
})
export class DDayFormat implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: string, format?: string): string {
    const today = new Date();
    const pastDate = new Date(value);
    const diff = Math.floor((Date.UTC(pastDate.getFullYear(), pastDate.getMonth(), pastDate.getDate()) - Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) / (1000 * 60 * 60 * 24));

    if (format) {
      return this.datePipe.transform(diff, format);
    }

    return `${diff}`;
  }

}