export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCQOcFKzueJH13h5z8UvGujZx3a62mQ3oE",
    authDomain: "buildmusic-3873d.firebaseapp.com",
    databaseURL: "https://buildmusic-3873d-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "buildmusic-3873d",
    storageBucket: "buildmusic-3873d.appspot.com",
    messagingSenderId: "888853832566",
    appId: "1:888853832566:web:a7edc1c515640af2bc1057",
    measurementId: "G-FFPYCDK1KY"
  },
  googleClientId: "888853832566-m83eupre0f3n37s7lmfea817c5jbnbd3.apps.googleusercontent.com",
 
  //contentserver:"https://app.unclewash.net/",
  //imgserver:"https://bmapi.unclewash.net",
  
  //uri:"https://bmapi.unclewash.net/graphql/"
  //uri:"http://localhost:5135/graphql/",
   uri:"https://api.buildmusik.com/graphql/",
  imgserver:"http://116.125.141.51",
};